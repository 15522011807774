import React, { useState } from 'react';
import './QuestionPopup.css';

type Props = {
  onCorrectAnswer: () => void;
};

const QuestionPopup: React.FC<Props> = ({ onCorrectAnswer }) => {
  const [currentQuestion, setCurrentQuestion] = useState(3);
  const [answers, setAnswers] = useState({ q1: '', q2: '', q3: '' });

  const handleAnswer = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setAnswers({ ...answers, [event.target.name]: event.target.value });
  };

  const nextQuestion = () => {
    if (currentQuestion === 3) {
        setCurrentQuestion(0);
    }
    else if (currentQuestion === 2) {
      if (answers.q1 === 'Yes' && answers.q2.toLowerCase() === 'ikrom' && answers.q3.toLowerCase() === 'sayyora') {
        onCorrectAnswer();
      } else {
        alert("хех; ты меня разочаровываешь....");
        setCurrentQuestion(0);
      }
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const renderQuestion = () => {
    switch (currentQuestion) {
      case 0:
        return (
          <div>
          <p>Кто самый лучший парень в мире?</p>
          <input type="text" name="q2" value={answers.q2} onChange={handleAnswer} className="popup-input" />
        </div>
        );
      case 1:
        return (
         
          <div>
          <p>Ты хочешь быть co мной?</p>
          <select name="q1" value={answers.q1} onChange={handleAnswer} className="popup-select">
            <option value="">Select</option>
            <option value="Yes">Да</option>
            <option value="Yes">Да</option>
          </select>
        </div>
        );
      case 2:
        return (
          <div>
            <p>Enter the password</p>
            <input type="text" name="q3" value={answers.q3} onChange={handleAnswer} className="popup-input" />
          </div>
        );
        
        case 3:
            return (
                <div>
                    <p>Подожди. Ты правда думала, что всё будет так просто? Сначала тебе нужно пройти тест.</p>
                </div>
            );
      default:
        return null;
    }
  };

  return (
    <div className="popup-container">
      {renderQuestion()}
      <button onClick={nextQuestion} className="popup-button">Next</button>
    </div>
  );
};

export default QuestionPopup;

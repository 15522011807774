import React, { useEffect, useRef } from 'react';
import celebrationGif from './assets/gif.gif';
import up from "./assets/up.mp3";

function Disco() {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current?.play();
    }
  }, []);

  return (
    <div style={{
      height: '100vh',
      width: '100%',
      backgroundImage: `url(${celebrationGif})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }}>
      <audio ref={audioRef} src={up} autoPlay loop />
    </div>
  );
}

export default Disco;

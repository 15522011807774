import { useState, useEffect } from 'react';
import backgroundGif from './assets/background.gif';
import QuestionPopup from './QuestionPopup';
import './App.css';
import {  Route, Routes, useNavigate } from 'react-router';
import Disco from './Disco';
import { BrowserRouter } from 'react-router-dom';


function Home() {

  const [isAnsweredCorrectly, setIsAnsweredCorrectly] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const fullText = `Моя любовь, в бескрайнем океане жизни, где волны трудностей и штормы сомнений часто заслоняют мой путь, ты стоишь высоко и непоколебимо, как маяк на берегу. Твоя любовь — это свет, который прорезает туман, ведя меня безопасно через самые темные ночи и бурные моря. С тобой я знаю, где дом, не из-за места на карте, а потому что твой свет приносит тепло и направление моему сердцу. Как корабль, ищущий гавань, я тянусь к твоему сиянию, находя мир и безопасность в свете твоей любви. Ты освещаешь мой путь, делая путешествие не просто терпимым, но и прекрасным.`;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);


  const handleCorrectAnswer = () => {
    setIsAnsweredCorrectly(true);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 600);
    });
  }, []);

  useEffect(() => {
    if (isAnsweredCorrectly && displayedText.length < fullText.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(fullText.substring(0, displayedText.length + 1));
      }, 30); 
      return () => clearTimeout(timeoutId);
    }
  }, [isAnsweredCorrectly, displayedText, fullText]);
  let navigate = useNavigate();

  const navigateToDisco = () => {
    navigate('/disco');
  };

  return (
    <div>
    <div style={{ 
      filter: isAnsweredCorrectly ? 'none' : 'blur(8px)',
      transition: 'filter 0.5s ease', // smooth transition for blur effect;
    }}>
      
      <div style={{
        backgroundImage: `url(${backgroundGif})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: 'white', 
        textShadow: '2px 2px 4px #000000', 
        paddingLeft: isMobile ? '5%' : '30%', 
        paddingRight: isMobile ? '5%' :'30%',
      }}>
        {isAnsweredCorrectly && <button onClick={navigateToDisco} style={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        background: 'linear-gradient(145deg, #fc466b, #3f5efb)',
        color: 'white',
        border: 'none',
        borderRadius: '20px',
        padding: '10px 20px',
        cursor: 'pointer',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        fontWeight: 'bold',
        letterSpacing: '1px',
        }}>Cheer me up</button>}
        {isAnsweredCorrectly && <div className="content neonText" >{displayedText}</div>}
      </div>
        
    </div>
    {!isAnsweredCorrectly && <QuestionPopup onCorrectAnswer={handleCorrectAnswer} />}
  </div>
  ); 
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/disco" element={<Disco />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
